exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-add-course-js": () => import("./../../../src/pages/admin/addCourse.js" /* webpackChunkName: "component---src-pages-admin-add-course-js" */),
  "component---src-pages-admin-add-lesson-course-id-js": () => import("./../../../src/pages/admin/addLesson/[courseId].js" /* webpackChunkName: "component---src-pages-admin-add-lesson-course-id-js" */),
  "component---src-pages-admin-add-user-js": () => import("./../../../src/pages/admin/addUser.js" /* webpackChunkName: "component---src-pages-admin-add-user-js" */),
  "component---src-pages-admin-edit-course-course-id-js": () => import("./../../../src/pages/admin/editCourse/[courseId].js" /* webpackChunkName: "component---src-pages-admin-edit-course-course-id-js" */),
  "component---src-pages-admin-edit-lesson-lesson-id-js": () => import("./../../../src/pages/admin/editLesson/[lessonId].js" /* webpackChunkName: "component---src-pages-admin-edit-lesson-lesson-id-js" */),
  "component---src-pages-admin-edit-user-user-id-js": () => import("./../../../src/pages/admin/editUser/[userId].js" /* webpackChunkName: "component---src-pages-admin-edit-user-user-id-js" */),
  "component---src-pages-admin-get-courses-js": () => import("./../../../src/pages/admin/getCourses.js" /* webpackChunkName: "component---src-pages-admin-get-courses-js" */),
  "component---src-pages-admin-get-lessons-js": () => import("./../../../src/pages/admin/getLessons.js" /* webpackChunkName: "component---src-pages-admin-get-lessons-js" */),
  "component---src-pages-admin-get-users-js": () => import("./../../../src/pages/admin/getUsers.js" /* webpackChunkName: "component---src-pages-admin-get-users-js" */),
  "component---src-pages-admin-index-js": () => import("./../../../src/pages/admin/index.js" /* webpackChunkName: "component---src-pages-admin-index-js" */),
  "component---src-pages-alunos-aulas-lesson-id-jsx": () => import("./../../../src/pages/alunos/aulas/[lessonId].jsx" /* webpackChunkName: "component---src-pages-alunos-aulas-lesson-id-jsx" */),
  "component---src-pages-alunos-change-email-js": () => import("./../../../src/pages/alunos/changeEmail.js" /* webpackChunkName: "component---src-pages-alunos-change-email-js" */),
  "component---src-pages-alunos-index-js": () => import("./../../../src/pages/alunos/index.js" /* webpackChunkName: "component---src-pages-alunos-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

